<template>
  <ion-page>
    <ion-content fullscreen>
      <!-- <h3>{{$t('surveyDescriptionText1')}}</h3> -->
      <template v-if="step == 1 || step == 2">
      <ion-card>
        <ion-card-header>
          <ion-card-title>{{$t('messageSurveyText5')}}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list lines="none">
            <ion-radio-group @ionChange="handleTypeSelect">
              <ion-item v-for="(item, key, index) in mainCategory" :key="index">
                <ion-label> {{ item.cate_name }}</ion-label>
                <ion-radio slot="start" :value="key" color="success"></ion-radio>
              </ion-item>
            </ion-radio-group>
       
          </ion-list>
        </ion-card-content>
      </ion-card>
      </template>

      <template v-if="step == 2">
        <ion-card>
          <ion-card-content>
            <ion-list>
                <ion-grid>
                  <ion-row size="12">
                    <ion-col size="3" style="display: flex; align-items: center;">
                      {{$t('labelItemCode')}}
                    </ion-col>
                    <ion-col size="9" >
                      <ion-input :value="modelCode" placeholder="" @ionInput="handleInput('modelCode', $event)" ></ion-input>
                    </ion-col>
                    <ion-col size="3" style="display: flex; align-items: center;">
                      {{$t('labelSerialAppl')}}
                    </ion-col>
                    <ion-col size="9">
                      <ion-input :value="serialNumber" placeholder="" @ionInput="handleInput('serialNumber', $event)"></ion-input>
                      <ion-button color="secondary" size="small" @click="handlemBarcodeModal">QR</ion-button>
                    </ion-col>                    
                  </ion-row>      
                </ion-grid>
            </ion-list>
          </ion-card-content>
        </ion-card>
        <ion-card>
          <ion-card-content>
            <ion-list>

                <ion-grid>
                  <ion-row size="12">
                    <template v-for="(item, index) in categoryList" :key="index">          
                      <ion-col size="3" style="display: flex; align-items: center;">
                        {{item.cate_name}}
                      </ion-col>
                      <ion-col size="9" >
                          <ion-select placeholder="select" @ionChange="handleSubSelect(index, $event)" >
                            <ion-select-option v-for="item2, index2 in item.subList" :key="index2" :value="item2.cate_num" >{{item2.cate_name}}</ion-select-option>
                          </ion-select>  
                      </ion-col>
                    </template>
                  </ion-row>
                </ion-grid>

            </ion-list>
          </ion-card-content>
        </ion-card>
      </template>

      <template v-if="step == 2">
        <ion-card>
          <ion-card-content>
            <ion-list>
              <ion-row size="12">
                <template v-for="(item, index) in photoMapping[categoryMainItemSelect].list" :key="index" >
                  <ion-col size="6">
                    <template v-if="photo[index+1] == undefined">
                      <ion-img src="/assets/images/common/no-image.png" @click="handlePhoto(index+1)" style="width: 100%;"/>
                    </template>
                    <template v-else> 
                      <ion-img
                        v-if="photo[index+1]"
                        :src="photo[index+1]"
                        @ionError="photo[index+1] = '/assets/images/common/no-image.png'"
                        @click="handlePhoto(index+1)"
                        style="width: 100%; height: 145px;"
                        />                            
                    </template>
                    <ion-button v-if="photo[index+1] === undefined" @click="handlePhoto(index+1)" style="width: 100%;" color="success">
                      <span>{{item}}</span>
                      <ion-icon :icon="cameraOutline"></ion-icon>
                    </ion-button>
                    <ion-button v-if="photo[index+1]" class="clear" @click="removePhoto(index+1)" style="width: 100%;" color="success">
                        <ion-icon :icon="trash"></ion-icon>
                     </ion-button>                      
                  </ion-col> 
                </template>
              </ion-row>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </template>

      <template v-if="step == 3">
        <ion-card>
          <ion-card-content>
            <ion-list>
              <ion-radio-group @ionChange="handleSpecialInfoSelect">
                <ion-item v-for="(item, _, index) in specialInfoList" :key="index">
                  <ion-label> {{ item.special_info_name }} ({{ String(item.add_cmp_amt).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }})</ion-label>
                  <ion-radio slot="start" :value="item" color="success"></ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </template>

      <ion-button :disabled="sncheck" color="success" expand="full" @click="handleNext">{{$t('buttonLabelNext')}}</ion-button>

      <ion-modal id="serial" :is-open="isOpenBarcodeModal" @willDismiss="onWillDismiss">
        <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script>
import Cookies from 'js-cookie'
import { 
  IonPage,
  IonContent, 
  IonButton, 
  IonCard, 
  IonCardHeader, 
  IonCardTitle, 
  IonCardContent,
  IonList,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
  IonSelect, 
  IonSelectOption,
  IonGrid,
  IonRow,
  IonCol,  
  IonModal,
//  toastController,
  useIonRouter
} from '@ionic/vue'
import { StreamBarcodeReader } from "vue-barcode-reader";
import { onMounted, ref} from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { cameraOutline, trash } from 'ionicons/icons'
import { Camera, CameraResultType } from '@capacitor/camera'
import { addFileData, listData, updateData, getData, addData } from '@/api/board/common'
import { DataURIToBlobNew } from '@/utils/commonUtils'
//addData
export default {
  name: 'survey',
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonCard, 
    IonCardHeader, 
    IonCardTitle, 
    IonCardContent,
    IonList,
    IonRadioGroup,
    IonItem,
    IonLabel,
    IonRadio,
    IonSelect,
    IonSelectOption,
    IonGrid,
    IonRow,
    IonCol,    
    IonModal,
    StreamBarcodeReader,
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const route = useRoute()
    const router = useIonRouter()
    let step = ref(0)
    const modelCode = ref(undefined)
    const serialNumber = ref(undefined) 
    //카테고리 정보 호출
    const mainCategory = ref({})
    const mainCategoryPrice  = ref([])
    const subCategory = ref([])
    const subCategoryPrice = ref([])
    //화면출려
    const categoryList = ref([])
    //선택정보
    const categoryMainItemSelect = ref(undefined)
    const categorySubItemSelect = ref([])
    //첨부파일
    const photo = ref([])
    const photoMapping = ref([])
    const photoFilename = ref([])
    //특가
    const com_main_num = ref(0)
    const specialInfoList = ref([])
    const specialInfo = ref({}) 

    //sn체크여부
    const sncheck = ref(false)

    let isOpenBarcodeModal = ref(false)

    //첨부파일 화면 출력 정보
    photoMapping.value[1] = {
      list : [t('labelPhoto14'), t('labelPhoto15'), t('labelPhoto16')]
    }
    photoMapping.value[2] = {
      list : [t('labelPhoto17'), t('labelPhoto18'), t('labelPhoto16'), t('labelPhoto19')]
    }
    photoMapping.value[3] = {
      list : [t('labelPhoto20'), t('labelPhoto18'), t('labelPhoto16'), t('labelPhoto21')]
    }
    photoMapping.value[4] = {
      list : [t('labelPhoto18'), t('labelPhoto22')]
    }
    photoMapping.value[6] = {
      list : [t('labelPhoto23'), t('labelPhoto24'), t('labelPhoto25'), t('labelPhoto26')]
    }

    onMounted(async () => {
      await getCategoryAll() //정보 호출
      await getComMainNum() //특가 정보      
    })
    
    //카테고리 호출
    const getCategoryAll = async () => {
      const apiUrl = '/api/appliances/allcategory'
      const response = await listData(apiUrl)

      response.forEach(item => {
        if(item.p_cate_key == '0' && item.is_del == 'N' && item.cate_num != '5' ){      
          mainCategory.value[item.cate_num] = item
          mainCategoryPrice.value[item.cate_num] = {price : item.price}
        }else if(item.is_del == 'N'){
          subCategory.value.push(item)
          subCategoryPrice.value[item.cate_num] = {price : item.price}
        }
      });
    }

    //선택 카테고리 상세 호출
    const handleTypeSelect =  async (e) => {

      const seelctVal = e.detail.value
      categoryMainItemSelect.value = seelctVal
      step.value = 2

      //스페셜정보 호출
      await getSpecialinfo()      

      if(subCategory.value.length > 0){
        const data = subCategory.value.filter((value) => {
          if(value.p_cate_key == seelctVal){
            //서브 카테고리 상세 리스트 가져옴
            const subList = subCategory.value.filter((value2) => {
              if(value.cate_num == value2.p_cate_key){
                return value2
              }
            });
            value.subList = subList
            //최종 정보 리턴
            return {
              value
            }
          }
        })
        categoryList.value = data
      }
    }

    //선택 카테고리 선택 정보
    const handleSubSelect = (index, e) => {
      categorySubItemSelect.value[index] = e.detail.value
    }

    //input 정보 호출
    const handleInput = (id, e) => {
      if (id === 'modelCode') {
        modelCode.value = e.target.value
      }else if (id === 'serialNumber') {
        serialNumber.value = e.target.value //SN
        sncheck.value = false
      }
    }

    //첨부파일 처리
    const handlePhoto = async (index) => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })
      photo.value[index] = image.dataUrl
    }

    //파일 삭제
    const removePhoto = async (index) => {
      photo.value[index] = undefined
    }

    //저장 처리
    const handleNext = async () => {

      const apiUrl = `/api/appliances/order/sncheck/`+categoryMainItemSelect.value
      const cnt = await getData(apiUrl, serialNumber.value);
      if(cnt > 0) {
        alert(t('messageSerialCheck'))
        sncheck.value = true
        return false;
      }

      let result = false
      const lsttCount = categoryList.value.length
      const selectCount = categorySubItemSelect.value.length

      if(step.value == 1 || step.value == 2){

        //구성품 선택 여부
        if(lsttCount != selectCount){
          alert(t('orderRequireInfo')+'[1]')
          return false
        }
        
        if(serialNumber.value === undefined || serialNumber.value == ''){
          alert(t('orderRequireInfo')+'[2]')
          return false
        }    
        
        if(modelCode.value === undefined || modelCode.value == ''){
          alert(t('orderRequireInfo')+'[3]')
          return false
        }

      }
      
      if(step.value == 2 && specialInfoList.value.length > 0){
        step.value = 3
      }else{
        result = true
      }

      if(result){
        if(postOrderPicture()){
          await postOrder()
          //프로모션
        }else{
          alert(t('networkError')+'[file]')        
          return false        
        }
      }
    }

    //데이터 저장
    const postOrder = async () => {
    
        const apiUrl = `/api/appliances/order/`
        const memNum = Cookies.get('memNum')
        let totalPrice = 0
        //data setting
        const formData = {}     
        formData[`num`] = Number(route.params.num)
        formData[`mem_num`] = memNum
        formData[`is_del`] = 'N'
        formData[`is_use`] = 'Y'
        formData[`is_confirm`] = 'N'   
        formData[`item_1_memo`] = ''
        formData[`item_1_num`] = categoryMainItemSelect.value
        formData[`item_1_price`] = mainCategoryPrice.value[categoryMainItemSelect.value].price        
        //선택 카테고리 정보
        let startNumber = 2
        categorySubItemSelect.value.forEach(value => {
          formData[`item_${startNumber}_memo`] = ''
          formData[`item_${startNumber}_num`] = value
          formData[`item_${startNumber}_price`] = subCategoryPrice.value[value].price
          totalPrice =+ subCategoryPrice.value[value].price
          startNumber ++
        });
        totalPrice =+ mainCategoryPrice.value[categoryMainItemSelect.value].price
        //프로모션 계산
        if(specialInfo.value.special_price !== undefined){
          totalPrice = specialInfo.value.special_price + totalPrice
        }
        formData[`total_price`] = totalPrice
        formData['model_code'] = modelCode.value
        formData['sn'] = serialNumber.value

        //프로모션 처리
        if(specialInfo.value.special_price !== undefined){
          formData['special_price'] = specialInfo.value.special_price
        }
        if(specialInfo.value.special_num !== undefined){
          formData['special_num'] = specialInfo.value.special_num
        }        
        
        //파일 정보 (파일 처리 이후 동작)
        photoFilename.value.forEach(value => {
          formData[`picture_${value.fileIndex}`] = value.fileName
        });
        
        await updateData(apiUrl, formData).then((response) => {      
          if(response){
            //TMS 발송
            addData('/api/appliances/sendtms', formData)
            router.push({
              name: 'appliances-checkup',
              params: {
                isState: 'check'
              }
            })
          }  
        })        
    }

    //첨부파일 업로드
    const postOrderPicture = async () => {
      const apiUrl = '/api/order/picture'
      const orderNum = route.params.orderNum
      const photoCount = photoMapping.value[categoryMainItemSelect.value].list.length
      let formData = new FormData()
      for(let i = 1; i <= photoCount; i++){
        if(photo.value[i] !== undefined){
          const file = DataURIToBlobNew(photo.value[i])
          const fileOfBlob = new File([file], `appliances_image_${i}.jpg`, {type:'image/jpeg'})
          photoFilename.value.push({
            fileIndex : i,
            fileName : `https://s3-ap-southeast-1.amazonaws.com/vnpicture.qcheck.kr/hardware/${orderNum}/appliances_image_${i}.jpg`
          })
          formData.append('file', fileOfBlob)
        }
      }

      formData.append('ord_number', orderNum)

      await addFileData(apiUrl, formData).then((response) => {        
        return response.result === 'success'? true : false      
      })      

    }

    //특가 
    const getComMainNum = async () => {
      const memId = Cookies.get('memId')
      const apiUrl = `/api/appliances/order/mainnum/${memId}`
      com_main_num.value = await listData(apiUrl)
      step.value = 1
    }

    const getSpecialinfo = async () =>{
      const comNum = com_main_num.value
      if(comNum > 0){
        const apiUrl2 = `/api/appliances/specialinfo/${comNum}`
        const specialInfo = await listData(apiUrl2)
        specialInfoList.value = specialInfo.filter(e => e.cate_name === mainCategory.value[categoryMainItemSelect.value].cate_name)    
      }
    }

    const handleSpecialInfoSelect = (item) =>{
      const data = item.detail.value
      specialInfo.value.special_price = data.add_cmp_amt
      specialInfo.value.special_num = data.special_info_id
    }

    const handlemBarcodeModal = () => {
      isOpenBarcodeModal.value = true
    }

    const onDecode = (result) => {
      serialNumber.value = result
      isOpenBarcodeModal.value = false
    }

    const onWillDismiss = () => {
      isOpenBarcodeModal.value = false
    };


    return {
      isOpenBarcodeModal,
      photo,
      photoMapping,
      specialInfoList,
      step,
      categoryMainItemSelect,
      mainCategory,
      modelCode,
      serialNumber,
      categoryList,
      cameraOutline,
      trash,
      handleInput,
      handleTypeSelect,
      handleSubSelect,
      handlePhoto,
      removePhoto,
      handleNext,
      handleSpecialInfoSelect,
      postOrderPicture,
      postOrder,
      onDecode,
      onWillDismiss,
      handlemBarcodeModal,
      sncheck
    }
  }
}
</script>

<style>
h3 {
  margin-bottom: 20px;
  font-size: 16px;
}

ion-card {
  margin-inline: 0;
}

ion-card-title {
  font-size: 16px;
}

ion-radio {
  margin-inline: 0;
  margin-inline-end: 15px;
}

ion-item {
  --padding-start: 0;
}

ion-tab-bar > ion-tab-button > ion-label {
  font-size:  14px;
}

ion-input {
  border: 1px solid var(--ion-color-medium);
}

ion-card ion-select {
  border: 1px solid var(--ion-color-medium);
  --padding-end: 5px;
}

ion-modal#serial {
  --width: fit-content;
  --min-width: 400px;
  --max-width: 80%;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

ion-button {
  min-height: 36px;
  height: auto;
  --padding-top: 11px;
  --padding-bottom: 11px;
}
</style>